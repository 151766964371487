import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout title="Page Not Found">
    <h1>Page Not Found</h1>
    <p>
      Oops! I can't seem to find the page you're looking for. <br />
      You can use one of the links in the menu above to find the{' '}
      <a href="/paintings">painting</a>, <a href="/drawings">drawing</a> or{' '}
      <a href="/etchings">etching</a>.
    </p>
  </Layout>
)

export default NotFoundPage
